// Bootstrap overrides

// Colors
//
// Grayscale and brand colors for use across Bootstrap.

$gray-dark:                 #263238;
$gray:                      #607d8b;
$gray-light:                #b0bec5;
$gray-lighter:              #cfd8dc;
$gray-lightest:             #f4f5f7;

$brand-primary:             #002880; //#3c67fe;
$brand-success:             #4dbd74;
$brand-info:                #63c2de;
$brand-warning:             #f8cb00;
$brand-danger:              #f86c6b;

$brand-dark-blue:           #000036;
$brand-blue:                #002880;
$brand-light-blue:          #0052cc; //lighten($brand-blue, 30%);
// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions:        true;
$enable-rounded:            false;

// Body
//
// Settings for the `<body>` element.

$body-bg:                   #fff;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base:            0.875rem;

// Breadcrumbs

$breadcrumb-bg:             #fff;

// Cards

$card-border-color:         $gray-lighter;
$card-cap-bg:               $gray-lightest;

// Dropdowns

$dropdown-padding-y:        0;
$dropdown-border-color:     $gray-lighter;
$dropdown-divider-bg:       $gray-lightest;

// Buttons

$btn-secondary-border:      $gray-light;

// Progress bars

$progress-bg:               $gray-lightest;

// Tables

$table-bg-accent:           $gray-lightest;
$table-bg-hover:            $gray-lightest;
