@media screen and (orientation: portrait) {
  .ant-btn .header-button-title {
    display: none;
  }
  .search-button--wrapper3 {
    text-align: left;
    button{
      > span {
        display: none;
      }
    }
  }
  //column title and sorter mobile alignment
  .ant-table-thead th:not(.nowrap) {
    text-align: left !important;

    .ant-table-column-sorter {
      display: block;
      margin-top: 2px;
    }
  }
  .ant-modal-footer {
    display: flex;
  }
  .download-button, .change-view {
    width: 32px;
    > span {
      display: none;
    }
    > i {
      margin: -6px;
    }
  }
  .favorites {
    .ant-btn {
      max-width: 145px;
    }
  }

  .sla-fail-cockpit-container .sla-chart-container {
    min-height: 150px;
    height: calc(125vh - 400px);
  }

  .cancellations-graph-cockpit-container .chart-container {
    min-height: 150px;
    height: calc(125vh - 400px);
  }
}
