.login-background-wrapper {
  height: 100vh;
  .row.justify-content-center > div {
    margin: 0 auto;
    padding-top: 50px;
  }
}

.header-fixed .app-body {
  margin-top: 0;
  z-index: 1;
}

.sidebar-fixed .sidebar {
  margin-top: 55px;
}

.app-header.navbar {

  .nav.navbar-nav.ml-auto {
    float: right;
    margin-top: 10px;
  }
  .nav.navbar-nav.d-md-down-none, .navbar-brand {
    float: left;
  }
  .nav.navbar-nav.d-md-down-none {
    margin-top: 10px;
  }
  .navbar-toggler.mobile-sidebar-toggler.d-lg-none {
    display: none;
  }
  .navbar-nav .nav-item {
    display: inline-block;
  }
}