// Here you can add other styles
.login-background-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: row !important;
  .logo{
    width: 180px;
    height: 65px;
    display: block;
    margin: 0 auto;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .login-background-card {
    border-radius: 8px !important;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
    margin-bottom: 0;
  }
  .login-form {
    input {
      text-align: center;
      padding: 4px 11px !important;
    }
  }
}

#root{
  width: 100%;
  height: 100%;
}

.app-body {
  overflow-y: hidden;
  background: #fff;
}

#root {
  z-index: 0;
}

.app-header.navbar .navbar-nav{
  margin-right: 10px;
}

.header-form {
  margin-bottom: 10px;
  display: block;
  width: 100%;
}

.header-text {
  margin-bottom: 20px;
}

input[readonly].ant-input {
  background: #f8f8f8;
  user-select: none;
  &:focus,
  &:hover {
    border: 1px solid #d9d9d9;
    box-shadow: none;
  }
}

.dashed-horizontal {
  border-top: 1px dashed;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

button:focus {
  outline: none;
}

.ant-notification {
  z-index: 1030 !important;
}

.language-selector {
  margin-right: 10px;
}

@include media-breakpoint-down(sm) {
  .language-selector {
    margin-right: 0;
  }
}

.right-checkbox {
  float: right;
}

$space_between_filters: 5px;
$sidebar-width: 230px;

.delayed-orders-filter {
  .date-range {

    > div {
      padding-right: $space_between_filters;
    }
  }
  .date-field {
    width: 100%;
    margin-bottom: 10px;
  }
  .combo-panel {
    > div {
      padding-right: $space_between_filters;
    }
  }
  .combo-panel > div > .combo{
    margin-bottom: 10px;
  }
  .combo {
    width: 100%;
  }

  > div > .remote-combo-box {
    padding-right: $space_between_filters;
  }
}

.is-loading{
    .ant-checkbox-wrapper {
        filter: invert(50%) hue-rotate(0deg) brightness(1.0);
        pointer-events: none;
    }
}
.is-loaded {
    .ant-checkbox-wrapper {
        filter: invert(0%) hue-rotate(0deg) brightness(1.0);
        pointer-events: auto;
    }
}

.remote-combo-box {
  margin-bottom: 10px;
  > .ant-checkbox-wrapper{
    font-size: 11px !important;
  }
}

.chart-pane {
  overflow-x: auto;
}

.ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only.download-button {
  font-size: 30px;
  padding: 0;
  width: 32px;
  height: 32px;
  border: none;

  &:focus {
    outline: 0;
    color: rgba(0, 0, 0, 0.65);
  }

  &:hover {
    color: #108ee9 !important;
  }
}

.default-modal, .error-modal {
    //width: 300px;
    //height: 150px;
    z-index: 100;

    h1 {
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 10px !important;
        margin-bottom: 20px;
        border-bottom: 1px solid rgb(204, 204, 204);
    }

    p {
        font-size: 14px;
        margin-bottom: 10px !important;
    }

    .ant-btn {
        margin-top: 20px;
        font-weight: 700;
    }
}

.error-modal {
    p {
        color: red !important;
    }
}

.tab-panel-message {
  margin-top: 20px;
}

:root .ant-table-column-sorter .anticon-caret-up,
:root .ant-table-column-sorter .anticon-caret-down {
  font-size: 16px !important;
}

.ant-table-tbody {
  background-color: #fff;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  word-break: normal !important;
  padding: 8px;
}

.ant-table-thead > tr > th .anticon-filter, .ant-table-thead > tr > th .ant-table-filter-icon {
  margin-left: 2px;
}

.table-icon--wrapper {
  align-self: center;
}

.ant-calendar-picker {
  min-width: 100px;
}

.col-date-range {
  display: inline-table !important;
}

.sales__layout {
  margin-bottom: 50px;
  .ant-layout-sider,
  .sales__layout--content,
  .ant-layout-header {
    //background-color: #fff;
  }
  .ant-layout-header {
    padding: 0;
    height: auto;
    min-height: 80px;
    line-height: 40px;
  }

  .date-range--wrapper {
    display: inline-flex;
    flex-direction: column;
    padding: 0 10px;
  }

  .ant-menu-item-group-title {

    font-size: 16px;
    padding: 0px 16px;
    margin-top: 16px;
    color: rgba(0, 0, 0, 1);
  }

  .ant-sales-dashboard-title2 {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 16px;
    -webkit-transition: all .3s;
    transition: all .3s;
    margin-bottom: 15px;
  }

  .ant-sales-dashboard-title {
    margin-top: 16px;
    padding: 0;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
  }

  .dateRangeTitle {
    white-space: nowrap;
  }
  .binning {
    display: block;
    height: 12px;
    margin-bottom: 5px;
  }
  .filter__currency-label {
    margin-bottom: 0;
  }
}

.search-button--wrapper {
  text-align: right !important;
}

.cockpit__box--wrapper.ant-card {
  text-align: center;
  margin-bottom: 10px;
  border-radius: 8px !important;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.2);
  .ant-card-head {
    background: #3483f0;
    border-radius: 8px 8px 0 0;
    border-bottom: none;
    .ant-card-head-title {
      font-size: 1rem;
      color: white;
    }
  }
  .ant-card-body {
    padding: 0;
    border-radius: 0 0 8px 8px;
    .ant-tabs.ant-tabs-top.ant-tabs-line, .ant-table-scroll {
      border-radius: 0 0 8px 8px;
    }
  }
  .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    overflow-y: hidden !important;
  }
  .ant-table-body {
    overflow-y: auto !important;
  }
}

.cockpit-row{
  .floating-boxes-500 {
    min-height: 500px;
  }
  .floating-boxes-550 {
    min-height: 550px;
  }
}

//@media screen and (max-width: 991px) {
//  .cockpit-row{
//    .floating-boxes-500 {
//      min-height: auto;
//    }
//    .floating-boxes-550{
//      min-height: auto;
//    }
//  }
//}

.ant-select-selection--multiple {
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
}

.ant-select-selection--multiple .ant-select-selection__clear {
  top: 14px;
  right: 20px;
}

.search-button--wrapper2 {
  //margin-right: 10px !important;
  display: inline-block;
  white-space: nowrap;
}

.search-button--wrapper3 {
  margin: 0 5px;
  text-align: center;
  button{
    padding: 0 10px;
    //margin: 0 2px;
    > span {
      display: inline;
    }
  }
  button:first-child{
    margin-right: 5px;
  }
}
@media (min-width: 800px){
  .search-button--wrapper3 {
    text-align: left;
    button{
      > span {
        display: none;
      }
    }
  }
}


/*
(.ant-select.ant-select-enabled:not(.ant-select-combobox) + .ant-table-column-sorter {
  margin-top: -15px;
}
// Safary only
_::-webkit-full-page-media, _:future, :root .ant-select.ant-select-enabled:not(.ant-select-combobox) + .ant-table-column-sorter {
  margin-top: 0;
}
*/

#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, .7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.ant-calendar-footer-btn.ant-input-disabled a {
  cursor: not-allowed;
}

.cockpit__graph--average {
  height: 42px;
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
}

.cockpit__graph--wrapper .language-selector {
  position: absolute;
  top: -25px;
  left: 10px
}

.ant-table-thead > tr > th > span {
  line-height: normal;
}

.ant-table-body {
  overflow: auto;
}

.app-footer > div {
  position: relative;
}

.app-footer .center {
  position: absolute;
  left: 50%;
}

.app-footer .center div {
  margin-left: -50%;
}

.dashboard__compare--wrapper.total-labels {
  line-height: 14px;
}

.coockpit-chart-gross-revenue-wrapper {
  position: relative;
  height: 388px;
}

.coockpit-chart-pie-wrapper {
  position: relative;
  height: 410px;
}

.invoice-summary {
  font-size: 16px;
  padding-top: 10px;
}

.table-footer-action {
  font-size: 16px;
}
.ant-table-tbody > tr.total-row > td {
  border:none;
}
.ant-table-tbody > tr.total-row:hover > td {
  background: #fff;
}

.ant-table-tbody > tr.total-row > td.total-bordered {
  border-bottom: 1px solid #e8e8e8;
}

.cancellation-charts, .cancellation-charts .ant-radio-group {
  margin-bottom: 16px;
}

/* notifications */
.notifications-container{
  width: 100%;
  position: fixed;
  z-index: 5000;
}

/* fix position of check mark in ant select option  */
:root .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:after{
  margin-right: -4px;
}


.margin-rows {
  margin-bottom: 16px;
}

.main .container-fluid {
  padding: 0 15px;
  margin-top: 15px;
}

.ant-select-selection-selected-value{
  padding-right: 16px !important;
}


@media screen and (min-width: 741px) {
  .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
    overflow-x: hidden !important;
  }
}
/*
.cockpit__graph--wrapper > div > canvas {
  max-width: 852px;
  max-height: 426px;
  min-width: 400px;
  min-height: 200px;
}*/
@media screen and (max-width: 740px) {
  .app-footer {
    display: inline-table !important;
  }
  /*.app-version-mobile{
    display: -webkit-box;
  }*/
  //table{
  //  width: 600% !important;
  //}
  .ant-col-sm-10{
    display: flex;
  }
  // TODO: brakes graph view height
  //.ant-tabs{
  //  overflow: scroll !important;
  //  margin-top: 10px;
  //}
  // TODO: check it on desktop version
  /*
  .chart-pane{
    width: 1024px !important;
    height: 768px !important;
  }*/
  .ant-spin-container{
    overflow: scroll !important;
  }
  .ant-spin-container .sales-bar {
    /*  width: 1024px !important;
      height: 768px !important;*/
  }
}
@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
    }
}

.ant-table{font-size:12px !important;}
.ant-pagination{font-size:12px !important;}
.reset-button{
    font-size:12px !important;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
    }
    70% {
        box-shadow: 0 0 0 5px rgba(0, 123, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
    }
}

@keyframes colorChange {
    0% {
        background-color: #007bff;
    }
    50% {
        background-color: #0056b3;
    }
    100% {
        background-color: #007bff;
    }
}

@keyframes scaleUp {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
}

@keyframes glow {
    0% {
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5), 0 0 10px rgba(0, 123, 255, 0.4), 0 0 15px rgba(0, 123, 255, 0.3), 0 0 20px rgba(0, 123, 255, 0.2), 0 0 25px rgba(0, 123, 255, 0.1);
    }
    50% {
        box-shadow: 0 0 10px rgba(0, 123, 255, 0.7), 0 0 20px rgba(0, 123, 255, 0.6), 0 0 30px rgba(0, 123, 255, 0.5), 0 0 40px rgba(0, 123, 255, 0.4), 0 0 50px rgba(0, 123, 255, 0.3);
    }
    100% {
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5), 0 0 10px rgba(0, 123, 255, 0.4), 0 0 15px rgba(0, 123, 255, 0.3), 0 0 20px rgba(0, 123, 255, 0.2), 0 0 25px rgba(0, 123, 255, 0.1);
    }
}

.search-button {
    font-size: 12px !important;
    background-color: #007bff;
    color: white;
    border: none;
    animation: glow 2s infinite, pulse 2s infinite, colorChange 3s infinite, scaleUp 2s infinite;
    transition: transform 0.3s;
}

.ant-tabs-nav-container{font-size:14px !important;}
.ant-input{font-size:12px !important;}
.ant-select{font-size:12px !important;}
//.ant-btn{line-height: 0.5 !important;}

.ant-menu-item, .ant-select-dropdown, .ant-checkbox-wrapper, .ant-calendar {
  font-size: 12px !important;
}

.ant-menu-item-group-title {
  font-weight: bold;
}

body {
  font-size:12px !important;
}

.cockpit__box--wrapper.ant-card .ant-card-head .ant-card-head-title {
  font-size: 1rem !important;}
.ant-card-head-title{font-size:14px !important;}
h2, .h2 {
  font-size: 2rem !important;
}
.table-footer-action{font-size:16px !important;}
h5, .h5 {
  font-size: 1.25rem !important;
}
@media screen and (max-width: 1784px) {
  .summary-footer {
    width: 18.3%;
    min-width: 150px;
    float: right;
    margin-right: 22.5%;
  }
}
@media screen and (max-width: 1700px) {
  .summary-footer {
    width: 18.5%;
    min-width: 228px;
    float: right;
    margin-right: 22.5%;
  }
}
@media screen and (max-width: 1600px) {
  .summary-footer {
    width: 17.0%;
    min-width: 228px;
    float: right;
    margin-right: 23.7%;
  }
  .summary-footer * td {
    padding: 6% !important;
  }

}
@media screen and (max-width: 1540px) {
  .summary-footer {
    margin-right: 21.8%;
  }
  .summary-footer * td {
    padding-right: 0 !important;
    padding-left: 0 !important;
    width: 50px !important;
    max-width: 50px !important;
  }
  .summary-footer * tr {
    width: 50px !important;
    max-width: 50px !important;
  }
  .summary-footer * colgroup > col {
    width: 100px !important;
    max-width: 100px !important;
  }
}
@media screen and (max-width: 1430px) {
  .summary-footer * colgroup > col {
    width: 70px !important;
    max-width: 70px !important;
  }
}
@media screen and (min-width: 1785px) {
  .summary-footer {
    width: 19%;
    min-width: 250px;
    float: right;
    margin-right: 21.8%;
  }
}
@media screen and (max-width: 1784px) {
  .summary-footer-p {
    width: 18%;
    min-width: 150px;
    float: right;
    margin-right: 17.2%;
  }
}
@media screen and (max-width: 1700px) {
  .summary-footer-p {
    width: 17.4%;
    min-width: 228px;
    float: right;
    margin-right: 18.1%;
  }
}
@media screen and (max-width: 1600px) {
  .summary-footer-p {
    width: 17.0%;
    min-width: 228px;
    float: right;
    margin-right: 18.8%;
  }
  .summary-footer-p * td {
    padding: 6% !important;
  }
}
@media screen and (max-width: 1540px) {
  .summary-footer-p {
    margin-right: 16.5%;
  }
  .summary-footer-p * td {
    padding-right: 0 !important;
    padding-left: 0 !important;
    width: 50px !important;
    max-width: 50px !important;
  }
  .summary-footer-p * tr {
    width: 50px !important;
    max-width: 50px !important;
  }
  .summary-footer-p * colgroup > col {
    width: 100px !important;
    max-width: 100px !important;
  }
}
@media screen and (max-width: 1430px) {
  .summary-footer-p * colgroup > col {
    width: 70px !important;
    max-width: 70px !important;
  }
}
@media screen and (min-width: 1785px) {
  .summary-footer-p {
    width: 19%;
    min-width: 250px;
    float: right;
    margin-right: 16.7%;
  }
}

.selected-up .ant-table-column-sorter .ant-table-column-sorter-up i{
  color: #1890ff;
}

.selected-down .ant-table-column-sorter .ant-table-column-sorter-down i{
  color: #1890ff;
}

.sla-risk {
  background-color: #ffaa49;

}

.sla-danger {
  background-color: #ff593f;
}

/* fix problem with footer on phone */
@media screen and (max-width: 767px) {
  .app-footer{
    width: 100%;
    transition: none !important;
  }
}

.app-version-mobile{
  white-space: nowrap;
}

.app-footer {
  padding: 1rem 1rem;
  line-height: 20px;

  .left-col {
    text-align: left;
    a {
      white-space: nowrap;
      margin-right: 10px;
    }
  }
  .center-col{
    text-align: center;
    a {
      color: black;
    }
  }
  .right-col{
    text-align: right;
  }
}

@media screen and (max-width: 767px) {
  .app-footer{
    .left-col{
      text-align: center;
      a, .app-version-mobile {
        display: inline-block;
        white-space: normal;
      }
    }
    .center-col {
      text-align: center;
    }
    .right-col {
      text-align: center;
    }
  }
}

/* fix filter sorter */
//.ant-table-thead > tr > th > span{
//  line-height: 32px !important;
//}
.ant-table-column-has-filters > span{
  display: inline-block;
  vertical-align: middle;
  //line-height: 30px;
  > * {
    vertical-align: middle;
  }
}
.ant-table-column-sorter-up, .ant-table-column-sorter-down {
  line-height: 12px !important;
}

.global-partner-filter-wrp {
  width: 200px;
  .ant-select {
    width: 100%;
  }
  .remote-combo-box{
    margin-bottom: 0;
  }
}

/* is admin mode */
@media (max-width: 799px) {
  .global-partner-filter-wrp {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    padding: 10px 15px 10px 15px;
    background: #eee;
    border-bottom: 1px solid #cfd8dc
  }
  .is-admin .app-body{
    margin-top: 108px;
  }

  .sidebar-mobile-show {
    .is-admin .app-body {
      margin-top: 55px;
    }
    .sidebar .sidebar-nav{
      height: calc(100vh - 60px);
    }
    .global-partner-filter-wrp {
      display: none;
    }
    .mobile-sidebar-toggler {
      transform: rotate(90deg);
    }
  }
}

/** charts **/
.chart-container {
  background-color: #fff;
  position: relative;
  padding: 5px 0;
//  height: calc(100vh - 340px);
  min-height: 411px;
  width: 100%;
  border: 1px solid #d9d9d9;
}

.sales-chart-container {
  background-color: #fff;
  position: relative;
  padding: 5px 0;
  min-height: 150px;
  height: calc(100vh - 400px);
  width: 100%;
  border: 1px solid #d9d9d9;
}

.sales-two-charts-container {
  position: relative;
  padding: 5px 0;
  min-height: 150px;
  height: calc(100vh - 2*400px);
  width: 100%;
}


.ant-table-column-sorters{
  display: flex;
}

//.ant-table-thead > tr > th .ant-table-column-sorter{
//  margin-top: -12.5px !important;
//}

.nowrap {
  white-space: nowrap;
}

.sortable .ant-dropdown-trigger {
  padding: 2px !important;
  font-size: inherit !important;
  font-weight: bold !important;
  margin-right: 5% !important;
  vertical-align: inherit !important;
}

.ant-table-thead .sortable:hover{
  background-color: whitesmoke;
  cursor: pointer;
}

.mac {
  .ant-table-body {
    &::-webkit-scrollbar {
      position: absolute;
      -webkit-appearance: none;
      height: 7px;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      background-color: rgba(0,0,0,.5);
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
  }
}

.cancellation-charts * .ant-card-head{
  background-color: unset;
}

// Fix spinner position in cockpit
.ant-spin-spinning {
  display: block;
}

.product-status-info {
  padding: 15px;
}

.product-status-info-title h5{
  font-weight:  bold;
  padding-bottom: 15px;
}

.product-status-info-content {
}

.product-status-info-content li{
  font-weight:  bold;
}

.product-status-info-content ol{
  margin: 0;
  padding: 0 0 0 15px;
}

.product-status-info-content ol div{
  padding-bottom:15px
}

.orders-submenu-item-link {
  padding: 0 !important;
}

.nav .nav-item {
  line-height: 0.9
}

.chart-legend-container {
}

.legend-row {
  display: flex;
  align-items: center;
}

.legend-row * {
  margin-left : 5px;
}

.legend-checkbox {
  margin: 0;
}

.legend-box {
  height: 15px;
  width: 15px;
  min-height: 15px;
  min-width: 15px;
  border: 1px solid #3c3c3d;
}

@media screen and (max-width: 780px) {
  .custom-pagination {
    margin: 16px 0 !important;
  }
}

.ant-menu-item-group-title {
  font-size: 13px;
  padding: 0 20px !important;
  color: #0f255a;
}

.sales-submenu-item {
  line-height: 29px !important;
  height: 29px !important;
  margin: 0px 0 !important;
  padding-left: 20px !important;
}

.orders-submenu-item {
  line-height: 30px !important;
  height: 30px !important;
  margin: 0px 0 !important;
  padding-left: 20px !important;
}

.submenu-group > .ant-menu-item-group-title {
  margin: 0 !important;
}

.composite-menu {
  max-height: inherit !important;
  li ul {
    max-height: inherit !important;
    overflow-x: hidden;
  }
}

.sidebar-nav::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 7px 0 3px #cbcbcb;
}

.product-status-title, .warehouse-stats-title {
  font-size: 1.25rem;
  font-weight: 500;
}

.invoice-summary-title {
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
}

@media screen and (min-width: 800px) and (max-width: 992px){
  .global-partner-filter-wrp {
    position: absolute;
    margin-left: 8%;
  }
}

@media screen and (max-height: 768px) and (orientation: landscape){
  body {
    font-size: 11px !important;
  }
  .ant-table * {
    font-size: 11px !important;
  }
  .sidebar * {
    font-size: 11px !important;
  }
  .ant-calendar * {
    font-size: 11px !important;
    .ant-calendar-footer {
      line-height: 2 !important;
    }
  }
  .ant-tabs-bar * {
    font-size: 13px !important;
  }
  .cockpit__box--wrapper.ant-card .ant-card-head .ant-card-head-title {
    font-size: 14px !important;
  }
  .remote-combo-box * {
    font-size: 11px !important;
  }
  .ant-btn * {
    font-size: 11px !important;
  }
  .ant-select *, .ant-card {
    font-size: 11px !important;
  }
  .ant-select-dropdown-menu-item, .ant-dropdown-menu-item {
    font-size: 11px !important;
  }
  // TODO: temporary disabled. Problem on desktop version. Ask Nikita.
  //th .ant-select {
  //  min-width: unset !important;
  //}
  .date-range * {
    font-size: 11px !important;
  }
  th > span > .ant-select > .ant-select-selection {
    width: 120px;
  }
  .ant-radio-group {
    font-size: 12px !important;
  }
  .ant-card-head-title {
    font-size: 12px !important;
  }
  .chart-container {
    min-height: 300px;
  }
  .ant-tabs-bar {
    margin-bottom: 8px;
  }
  .mb-3 {
    margin-bottom: 8px !important;
      > .p {
          display: table-cell;
          vertical-align: middle;
      }
    > .change-view {
        display: table-cell;
        vertical-align: middle;
    }
    > .download-button {
        display: table-cell;
        vertical-align: middle;
     }
  }

  .download-report-button {
    height: 32px;
    line-height: 0 !important;
  }
  .legend-checkbox .ant-checkbox-inner {
    width: 13px !important;
    height: 13px !important;
  }
  .legend-checkbox {
    height: 13px;
    .ant-checkbox {
      top: 0 !important;
      height: 14px !important;
      vertical-align: unset;
    }
    .ant-checkbox-inner:after {
      left: 3px;
      top: 0;
    }
  }
  .date-field {
    margin-bottom: 0 !important;
  }
  .chart-legend-container {
    * {
      font-size: 12px;
    }
    .legend-row {
      .legend-box {
        height: 12px;
        width: 12px;
        min-height: 12px;
        min-width: 12px;
      }
    }
  }
  .ant-table-fixed {
    line-height: 1;
  }
  .ant-table {
    line-height: 1;
  }
  .ant-sales-dashboard-title {
    font-size: 14px !important;
  }
  .ant-sales-dashboard-title2 {
    font-size: 12px !important;
  }
  .dashboard__compare--wrapper * {
    font-size: 11px !important;
  }
  .ant-tooltip-content {
    font-size: 12px;
  }
  .tab-panel-message {
    margin-top: 5px !important;
    font-size: 12px;
  }
  .cockpit__graph--average {
    font-size: 12px !important;
  }
  .product-status-title, .warehouse-stats-title {
    font-size: 14px;
  }
  .invoice-summary-title {
    font-size: 18px;
  }
  .table-footer-action {
    font-size: 11px !important;
  }
  .sidebar-mobile-show {
    .container-fluid {
      width: 71.2% !important;
      margin-left: unset;
    }
    .app-footer {
      width: 71.2% !important;
      margin-left: $sidebar-width !important;
    }
  }
  .delayed-orders-filter-buttons > div {
    width: 67px;
    .search-button--wrapper3 {
      display: inline-flex;
    }
  }
}

/**
  SIDEBAR MENU
 */
.sidebar{
  .ant-menu-inline {
    > .ant-menu-item {
      line-height: 36px;
      height: 36px;
      -webkit-transition: none;
      transition: none;
      > a {
        color: #c7c7c7;
        padding-left: 20px;
      }
      .anticon {
        font-size: 12px;
        min-width: 12px;
      }
    }
    > .ant-menu-submenu {
      line-height: 36px;
      -webkit-transition: none;
      transition: none;
      .ant-menu {
        a {
          color: #c7c7c7;
          padding-left: 20px;
        }
      }
      .ant-menu-submenu-title {
        line-height: 36px;
        height: 36px;
      }
    }
  }
  .ant-menu-dark .ant-menu-inline.ant-menu-sub{
    box-shadow: inset -4px 0px 9px -1px rgba(0,0,0,0.14);
  }
  .ant-menu-submenu, .ant-menu-submenu-inline{
    -webkit-transition: none;
    transition: none;
  }
  .ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
    font-size: 12px;
    margin: 0;
    color: #c7c7c7;
    -webkit-transition: none;
    transition: none;
  }
  .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title > span {
    padding-left: 20px;
  }
  .ant-menu-dark span i {
    margin-right: 5px;
  }
  .ant-menu-dark .ant-menu-item-active > a{
    color: #fff;
    -webkit-transition: none;
    transition: none;
  }
  .ant-menu-dark .ant-menu-item-active,
  .ant-menu-dark .ant-menu-submenu-active
  {
    color: #fff;
    background: #002880!important;
    -webkit-transition: none;
    transition: none;
  }

  .ant-menu-dark .ant-menu-item-selected,
  .ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title {
    color: #fff;
    background: #0f255a;
  }

  .ant-menu-dark .ant-menu-inline.ant-menu-sub{
    background: $gray-lightest;
    padding: 6px 0;
    .ant-menu-item{
      line-height: 22px;
      height: 22px;
      > a{
        color: #607d8b;
      }
    }
    .ant-menu-item-active{
      background: none !important;
      > a{
        color: $brand-light-blue;
      }
    }
    .ant-menu-item-selected{
      background: #fff !important;
      width: 99.9%;
      box-shadow: inset -12px 0px 9px -10px rgba(0,0,0,0.20);
      > a{
        color: $brand-light-blue;
      }
    }
  }
  .ant-menu-item-group{
    .ant-menu-item-group-list{
      padding: 4px 0px;
    }
    .ant-menu-item-group-title {
      color: rgba(0, 0, 0, 1);
    }
    .ant-menu-item {
      line-height: 32px;
      height: 32px;
      > a {
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 24px;
      }
    }
  }

}


.ant-menu, .ant-menu-item > a {
  color: $gray;
}

.ant-menu-dark, .ant-menu-dark .ant-menu-sub{
  background: none;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item{
  margin: 0;
}
.ant-btn-primary{
  background-color: $brand-light-blue;
  border-color: $brand-light-blue;
}
//.ant-menu-item:active, .ant-menu-submenu-title:active{
//  background: #fff;
//}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  background-color: $brand-light-blue;
  border-color: $brand-light-blue;
}

.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover{
  color: $brand-light-blue;
}
.ant-menu-item-selected, .ant-menu-item-selected > a, .ant-menu-item > a:hover{
  color: $brand-light-blue;
}
.ant-menu-vertical .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-inline .ant-menu-item:after{
  right: auto !important;
  left: 0 !important;
  border-left: none !important;
  border-right: none !important;
}
//.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
//  background-color: #fff !important;
//}
//
.ant-tabs-nav .ant-tabs-tab-active {
  color: $brand-light-blue;
}
.ant-tabs-ink-bar{
  background-color: $brand-light-blue;
}
.ant-tabs-nav .ant-tabs-tab:hover,
.ant-tabs-nav .ant-tabs-tab:active{
  color: $brand-light-blue;
}
.submenu-group{
  background: $gray-lightest;
}

.ant-checkbox-checked .ant-checkbox-inner, .ant-checkbox-indeterminate .ant-checkbox-inner{
  background-color: $brand-light-blue;
  border-color: $brand-light-blue;
}
.selected-up .ant-table-column-sorter .ant-table-column-sorter-up i,
.selected-down .ant-table-column-sorter .ant-table-column-sorter-down i{
  color: $brand-light-blue;
}

::selection {
  background-color: $brand-light-blue;
  color: white;
}

.ant-table-wrapper {
  margin-top: 10px;
}
.cockpit-row {
  margin-top: 10px;
}
.cockpit__box--wrapper .ant-table-content {
  max-height: 349px;
  overflow-y: auto;
}
.filter-form-collapse-panel {

  background-color: inherit !important;
  .ant-collapse-item {
    background-color: inherit !important;
    > .ant-collapse-header {
      &:hover {
        background-color: #fafafa;
      }
      .arrow {
        left: 12px;
        font-size: 10px;
      }
    }
    .ant-collapse-content {
      padding: 0;
    }
  }
}

.legend-row.legend-related.selected {
  background: #f4f5f7;
  box-shadow: inset -4px 0px 16px -1px rgba(0, 0, 0, 0.14);
  font-weight: 700;
}

.download-button > i {
  font-size: 14px;
}

.url-download {
  line-height: 2 !important;
}

.sla-fail-cockpit-container .sla-chart-container {
  min-height: 150px;
  height: calc(70vh - 400px);
  margin-top: 10px;
}

.cancellations-graph-cockpit-container .chart-container {
  min-height: 150px;
  height: calc(70vh - 400px);
  margin-top: 10px;
}

.cockpit__box--wrapper .filter-form-collapse-panel {
  text-align: left;
  margin-left: 10px;
}


.ant-table {
  border: 1px solid #d9d9d9;
}

.cockpit__box--wrapper .ant-table {
  border: unset;
}

.sla-analysis-graph-wrapper .ant-card {
  border: 1px solid #d9d9d9;
}
.cancellation-charts .ant-card {
  border: 1px solid #d9d9d9;
}
.chart-container-pie {
  position: relative;
  padding: 5px 0;
  min-height: 411px;
  width: 100%;
}

//table load spinners
.spinner-custom {
  .css-82vd5q {
    .css-13a7gsp {
      animation: animation-p3pskt 1.2s 0.96s infinite ease-in-out;
    }
    .css-1yv96sn {
      animation: animation-p3pskt 1.2s 0.84s infinite ease-in-out;
    }
    .css-1f2v7ja {
      animation: animation-p3pskt 1.2s 0.72s infinite ease-in-out;
    }
    .css-1bh4lta {
      animation: animation-p3pskt 1.2s 0.60s infinite ease-in-out;
    }
    .css-1a5mw8j {
      animation: animation-p3pskt 1.2s 0.48s infinite ease-in-out;
    }
    .css-50snwb {
      animation: animation-p3pskt 1.2s 0.36s infinite ease-in-out;
    }
    .css-1hcfczu {
      animation: animation-p3pskt 1.2s 0.24s infinite ease-in-out;
    }
    .css-1tcaxoy {
      animation: animation-p3pskt 1.2s 0.12s infinite ease-in-out;
    }
  }
}
.spinner-short {
  .css-82vd5q {
    div {
      height: 7px;
      width: 7px;
    }
  }
}
.spinner-long {
  .css-82vd5q {
    div {
      height: 17px;
    }
  }
}
.spinner-circle {
  .css-82vd5q {
    div {
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }
  }
}
