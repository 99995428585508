body, html {
  width: 100%;
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  min-height: 100vh;
  -webkit-flex-direction: row !important;
  flex-direction: row !important;
  margin: 0;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #263238;
  background: url("../img/background.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow-y: overlay;
}

body.main-screen {
  background: #fff;
}

.main {
  //background: url("../img/bg-header-and-testimonials.png") no-repeat center center fixed;
  background-size: 100% 100%;
}

img {
  vertical-align: middle;
  border-style: none;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

#root {
  width: 100%;
}

.app {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

.app-loader {
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .app-loader-container {
    width: 350px !important;
    height: 320px;
    text-align: center;
    background: #fff;
    border: 1px solid #cfd8dc;

    > div {
      padding: 45px;
    }
  }

  .spinner {
    margin-top: 30px;
  }

  .logo {
    width: 180px;
    padding-bottom: 10px;
  }

  .version {
    button{
      display: none;
    }
  }
}

.offline .app{

  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in IE 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}

.offline-status{
  display: flex;
  z-index: 2000;
  background-color: rgba(255, 255, 255, 0.9);
  position: fixed;
  width: 100%;
  height: 100%;
  -webkit-align-items: center !important;
  align-items: center !important;
  .container{
    text-align: center;
    margin: 0 auto;
  }
}

.app-loader, .offline-status {
  button {
    line-height: 1.5;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 4px;
    height: 32px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: relative;
    color: #fff !important;
    background-color: #1890ff;
    border: 1px solid #1890ff;
    &[disabled] {
      color: rgba(0, 0, 0, 0.25) !important;
      background-color: #f5f5f5;
      border-color: #d9d9d9;
    }
  }
}
